<template>
  <div class="navbar-lottary">
    <nav class="navbar-lottary-wrapper">
      <ul class="links-menu-buy">
        <router-link to="#"><li>ตะกร้า</li></router-link>
        <router-link to="#"><li>ประวัติการซื้อ</li></router-link>
        <router-link to="#"><li>ค้นหาเลขเด็ด</li></router-link>
        <router-link to="#"><li>ค้นหาตัวแทน</li></router-link>
        <router-link to="#"><li>ร้อนเรียน</li></router-link>
      </ul>
    </nav>
  </div>

  <div class="search-lottary">
    <div class="search-lottary-wrapper">
      <div class="search-lottary-card">
        <div class="search-lottary-header">
          <h2>กรอกเลขลอตเตอรี่ที่ต้องการค้นหา</h2>
        </div>
        <div class="search-lottary-content">
          <form>
            <div class="input-search">
              <ul class="split-input-wrapper">
                <li class="split-input">
                  <input type="text" v-model="input1" pattern="\d*" maxlength="1" size="1" id="1" placeholder=" X " autocomplete="off" />
                </li>
                <li class="split-input">
                  <input type="text" v-model="input2" pattern="\d*" maxlength="1" size="1" id="2" placeholder=" X " autocomplete="off" />
                </li>
                <li class="split-input">
                  <input type="text" v-model="input3" pattern="\d*" maxlength="1" size="1" id="3" placeholder=" X " autocomplete="off" />
                </li>
                <li class="split-input">
                  <input type="text" v-model="input4" pattern="\d*" maxlength="1" size="1" id="4" placeholder=" X " autocomplete="off" />
                </li>
                <li class="split-input">
                  <input type="text" v-model="input5" pattern="\d*" maxlength="1" size="1" id="5" placeholder=" X " autocomplete="off" />
                </li>
                <li class="split-input">
                  <input type="text" v-model="input6" pattern="\d*" maxlength="1" size="1" id="6" placeholder=" X " autocomplete="off" />
                </li>
              </ul>
            </div>
            <div class="button-lottary">
              <ul class="button-lottary-wrapper">
                <div class="item">
                  <li class="button-choice">
                    <button type="button" class="button-item active">เลขท้าย 3 ตัว</button>
                  </li>
                </div>
                <div class="item">
                  <li class="button-choice">
                    <button type="button" class="button-item">เลขหน้า 3 ตัว</button>
                  </li>
                </div>
                <div class="item">
                  <li class="button-choice">
                    <button type="button" class="button-item">เลขท้าย 2 ตัว</button>
                  </li>
                </div>
              </ul>
            </div>
            <div class="button-option">
              <div class="button-reset">
                <div class="button-wrapper">
                  <button type="button" class="button-item reset">ลบตัวเลข</button>
                </div>
              </div>
              <div class="button-search">
                <div class="button-wrapper">
                  <button type="button" class="button-item search">ค้นหาล็อตเตอรี่</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BuyLottary',
  data() {
    return {}
  }
}
</script>
